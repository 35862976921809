<template lang="pug">
b-modal.vc-modal-k-carousel-ads-modal(:active.sync="isImageModalActive")
  b-carousel(
    :pause-info="false"
    :interval="7000"
    :arrow="false"
    :indicator-inside="false"
  )
    b-carousel-item(
      v-for="(data, index) in modalAds"
      :key="index"
    )
      a.link(:href="data.url")
        img.img(
          :src="isMobile ? data.mobile_image.url || data.image.url : data.image.url"
          :alt="data.alt_text"
        )
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'skid-composables'

const MOBILE_IMAGE_BREAK_POINT = 769
const store = useStore()

// props
const props = defineProps<{
  useType: String
}>()

// data
const isImageModalActive = ref(false)

// computed
/**
 *
 * workaround for safari can't get window width from vuex at first time loading
 *
 * @returns {computedRef<Boolean>}
 */
const isMobile = computed<Boolean>(() => {
  return (
    (store.getters['windowWidth'] || window.innerWidth) <
    MOBILE_IMAGE_BREAK_POINT
  )
})
/**
 * @returns {computedRef<Array<Object>>}
 */
const modalAds = computed<Array<Object>>(() => {
  return store.getters['modalLinks/allByType'](props.useType)
    .filter((link) => link.rules.activated)
    .sort((a, b) => a.position - b.position)
})

// mounted
onMounted(async () => {
  await _fetchModalLinks()
})

// methods
/**
 * @returns {void}
 */
const _fetchModalLinks = async () => {
  await store.dispatch('modalLinks/allByUseType', {
    filter: props.useType
  })

  if (modalAds.value.length > 0) isImageModalActive.value = true
}
</script>
